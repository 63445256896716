import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps, useFormikContext } from "formik";

import { AddButton } from "common/components/AddButton/AddButton";
import { useAppDialog } from "hooks/useAppDialog";
import { OrganizationFlat } from "types/Organization";
import { AddOrganizationForm, DialogFormValues } from "./AddOrganizationForm";
import { FormValues } from "./EditUserForm";

interface AddOrganizationProps {
  onAddOrganization: (organizationId: OrganizationFlat) => void;
  currentOrganizationsIds?: string[];
}

export const AddOrganization = ({
  onAddOrganization: onAddOrganizationCallback,
  currentOrganizationsIds = []
}: AddOrganizationProps) => {
  const context = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();

  const formRef = useRef<FormikProps<DialogFormValues>>(null);

  const onAddOrganization = (organizations: Array<OrganizationFlat>) => {
    context.setFieldValue("organizations", [
      ...context.values.organizations,
      ...organizations,
    ]);
    if(organizations[0]) onAddOrganizationCallback(organizations[0]);
    closeDialog();
  };

  const onSubmit = () => {
    formRef.current?.handleSubmit();
  };

  return (
    <>
      <AddButton variant="contained" fullWidth onClick={openDialog}>
        {t("AddOrganization")}
      </AddButton>
      <Dialog
        {...dialogProps}
        title={t("Organizations")}
        loading={false}
        onSubmit={onSubmit}
        actionText={t("Save")}
        maxWidth="sm"
      >
        <AddOrganizationForm
          onSubmit={onAddOrganization}
          formRef={formRef}
          currentOrganizationsIds={currentOrganizationsIds}
        />
      </Dialog>
    </>
  );
};