import { RefObject, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import { addYears } from "date-fns";
import { Form, Formik, FormikProps } from "formik";
import { array, date, object, string } from "yup";

import { AppSelect } from "common/components/AppSelect/AppSelect";
import { DatePicker } from "common/components/DatePicker/DatePicker";
import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { OrganizationUserSelect } from "common/components/OrganizationUserSelect/OrganizationUserSelect";
import { PermissionsField } from "common/components/PermissionsField/PermissionsField";
import { Tabs, useTabValue } from "common/components/Tabs/Tabs";
import { TextField } from "common/components/TextField/TextField";
import { Application } from "hooks/useGetUtilityApps";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { ApiTokenData, CreateApiTokenData } from "types/Api";
import { User } from "types/User";
import {AddUser} from "../../common/components/AddUser/AddUser";
import { SettingsArrayField } from "./SettingsArrayField";

export type FormValues = Omit<
  ApiTokenData,
  "userId" | "timeoutInSeconds" | "permissions" | "settings" | "applicationId"
> & {
  user: null | User;
  expireDate: null | Date;
  permissionsId: Array<string>;
  settingsArray: Array<{ key: string; value: string }>;
  application: null | Application;
};

interface AddTokenFormProps {
  onSubmit: (values: CreateApiTokenData) => void;
  formRef: RefObject<FormikProps<FormValues>>;
}

type TabLabel = "Permissions" | "Settings";

export const AddTokenForm = ({
  onSubmit,
  formRef,
}: AddTokenFormProps): JSX.Element => {
  const { t } = useTranslation();
  const [tabsValue, setTabsValue] = useTabValue<TabLabel>("Permissions");
  const { selectedOrganizationId } = useSelectedOrganization();
  const minDate = useMemo(() => new Date(), []);
  const initialValues = useMemo(
    () => ({
      user: null,
      orgId: selectedOrganizationId as string,
      application: null,
      expireDate: addYears(new Date(), 10),
      name: "",
      description: "",
      permissionsId: [],
      settingsArray: [],
    }),
    [selectedOrganizationId]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        user: object().required(t("Required")).nullable(true),
        application: object().required(t("Required")).nullable(true),
        expireDate: date().required(t("Required")).nullable(true),
        name: string().required(t("Required")),
        permissionsId: array().min(1, t("OnePermissionRequired")),
        settingsArray: array().of(
          object().shape({
            key: string().required(t("Required")),
            value: string().required(t("Required")),
          })
        ),
      }),
    [t]
  );

  const onFormSubmit = useCallback(
    ({
      user,
      expireDate,
      permissionsId,
      settingsArray,
      application,
      ...values
    }: FormValues) =>
      onSubmit({
        ...values,
        application: application?.id as string,
        userId: user?.id as string,
        permissions: permissionsId.map((id) => id),
      }),
    [onSubmit]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      {({ values, errors }) => {
        return (
          <Form>
            <FormGridContainer>
              <Grid item xs={12}>
                <TextField name="name" label={t("Name")} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  name="expireDate"
                  label={t("ExpirationDate")}
                  minDate={minDate}
                />
              </Grid>
              <Grid item xs={6}>
                <AppSelect
                  name="application"
                  label={t("Application")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <AddUser name="user" label={t("User")} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={3}
                  label={t("Description")}
                  name="description"
                  fullWidth
                />
              </Grid>
            </FormGridContainer>
            <TabContext value={tabsValue}>
              <Tabs onChange={setTabsValue}>
                <Tab
                  label={t("Permissions")}
                  value={"Permissions"}
                  iconPosition="end"
                />
                <Tab
                  label={t("Settings")}
                  value={"Settings"}
                  icon={
                    errors.settingsArray ? (
                      <ErrorOutlineIcon fontSize="small" color="error" />
                    ) : undefined
                  }
                  iconPosition="end"
                />
              </Tabs>
              <Box mt={2}>
                <TabPanel value="Permissions">
                  <PermissionsField
                    name="permissionsId"
                    columns={2}
                    applicationName={values.application?.name ?? ""}
                  />
                </TabPanel>
                <TabPanel value="Settings" sx={{ alignItems: "flex-start" }}>
                  <SettingsArrayField name="settingsArray" />
                </TabPanel>
              </Box>
            </TabContext>
          </Form>
        );
      }}
    </Formik>
  );
};