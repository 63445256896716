import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorIcon from "@mui/icons-material/Error";
import SearchIcon from "@mui/icons-material/Search";
import {
  Grid, InputAdornment,
  List,
  ListItemText,
  Skeleton,
  Stack, TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useField, useFormikContext } from "formik";

import { ListItemButtonWithDialog } from "common/components/ListItemButtonWithDialog/ListItemButtonWithDialog";
import { ListSkeleton } from "common/components/ListSkeleton/ListSkeleton";
import {OrganizationFlat} from "../../../../../types/Organization";
import { AddOrganization } from "../AddOrganization";
import { FormValues } from "../EditUserForm";
import { OrganizationGroups } from "../OrganizationGroups";
import { RemoveUserOrganization } from "../RemoveUserOrganization";

const highlight = (text: string, search: string) => text.replace(new RegExp(search, "gi"), (match) => `<mark>${match}</mark>`);

export const AccountMembership = (): JSX.Element => {
  const { t } = useTranslation();
  const { values, errors, initialValues } = useFormikContext<FormValues>();
  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState<string>("");

  function reloadFilteredOrgs() {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filteredOrgs = values.organizations.filter((org) =>
        org.name.toLowerCase().includes(searchedOrganization.toLowerCase())
    );
    setFilteredOrganizations(filteredOrgs);
  }

  const onAddOrganization = useCallback((organization: OrganizationFlat) => {
    setFilteredOrganizations((prevState) => {
      const output = prevState;
      output.push(organization);
      return output;
    })
    setSelectedOrganizationId(organization.id);
  }, []);

  const checkSelectedOnDelete = useCallback((organizations: OrganizationFlat[]) => {
    setFilteredOrganizations((prevState) => {
      if(organizations.length > 0) {
        setSelectedOrganizationId(organizations[0].id);
      } else {
        setSelectedOrganizationId("");
      }
      return organizations;
    })
  }, []);

  const selectedOrganization = useMemo(
    () =>
      values?.organizations?.find(
        (organization) => organization?.id === selectedOrganizationId
      ),
    [selectedOrganizationId, values?.organizations]
  );

  useEffect(() => {
    reloadFilteredOrgs();
    setSelectedOrganizationId(values.organizations[0]?.id);
  }, [initialValues]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, { error: organizationsError }] = useField("organizations");

  useEffect(() => {
    sessionStorage.setItem("editUserSelectedId", selectedOrganizationId);
  }, [selectedOrganizationId]);

  const currentOrganizationsIds = useMemo(() => {
    let orgIds: string[] = [];
    if (values && values.organizations) {
      orgIds = values.organizations.map((org) => {
        return org.id;
      });
    }
    return orgIds;
  }, [values]);

  const onChange = useCallback(
      (event) => {
        setSearchedOrganization(event.target.value)
      },
      []
  );
  
  const [searchedOrganization, setSearchedOrganization] = useState("");

  useEffect(() => setSearchedOrganization(""), [selectedOrganizationId]);

  const [filteredOrganizations, setFilteredOrganizations] = useState<OrganizationFlat[]>([]);
  useEffect(() => {
    reloadFilteredOrgs();
  }, [searchedOrganization]);

  return (
    <Grid container spacing={2} pt={2}>
      <Grid item xs="auto">
        <Stack direction="column" sx={{ maxHeight: "100%" }}>
          <TextField
              fullWidth
              placeholder={t("StartTypingToSearch")}
              label={t("SearchOrganizations")}
              type="search"
              onChange={onChange}
              value={searchedOrganization}
              InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                ),
              }}
              sx={{pb: 1}}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ pl: 0, pb: 2 }}
          >
            <Typography noWrap overflow="unset" mr={2}>
              {t("Organizations", {
                count: values.organizations.length,
              })}
            </Typography>
            <AddOrganization
              onAddOrganization={onAddOrganization}
              currentOrganizationsIds={currentOrganizationsIds}
            />
          </Stack>
          {organizationsError && (
            <span
              style={{
                fontSize: "14px",
                width: "100%",
                color: "red",
                fontWeight: "300",
                marginBottom: "10px",
              }}
            >
              {organizationsError}
            </span>
          )}

          <List sx={{ py: 0, overflow: "auto", height:'calc(100vh - 610px)' }}>
            {filteredOrganizations && filteredOrganizations.map((organization) => {
              const hasError = (
                errors?.organizations as unknown as Record<string, string>
              )?.[organization?.id];
              return (
                <ListItemButtonWithDialog
                  key={organization.id}
                  selected={organization?.id === selectedOrganization?.id}
                  divider
                  onClick={() => setSelectedOrganizationId(organization?.id)}
                >
                  <ListItemText
                    sx={{
                      mr: 2,
                      color: (theme) =>
                        hasError ? theme.palette.error.main : undefined,
                    }}
                  >
                      <span
                          dangerouslySetInnerHTML={{
                              __html: highlight(organization?.name, searchedOrganization),
                          }} />
                    {hasError && (
                      <Tooltip title={hasError} placement="top">
                        <ErrorIcon color="error" fontSize="small" />
                      </Tooltip>
                    )}
                  </ListItemText>
                  {organization?.id !== values?.defaultOrganization?.id && (
                    <RemoveUserOrganization
                      organization={organization}
                      onDelete={checkSelectedOnDelete}
                    />
                  )}
                </ListItemButtonWithDialog>
              );
            })}
          </List>
        </Stack>
      </Grid>
      <Grid item xs>
        {selectedOrganization && (
          <OrganizationGroups selectedOrganization={selectedOrganization} />
        )}
      </Grid>
    </Grid>
  );
};

export const AccountMembershipLoading = (): JSX.Element => {
  return (
    <Grid container spacing={2} pt={2}>
      <Grid item xs="auto">
        <Stack direction="column" sx={{ maxHeight: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ pl: 2, pb: 2 }}
          >
            <Typography noWrap overflow="unset" mr={2}>
              <Skeleton variant="text" width={160} />
            </Typography>
          </Stack>
          <ListSkeleton withDivider width={300} />
        </Stack>
      </Grid>
      <Grid item xs>
        <ListSkeleton width={300} />
      </Grid>
    </Grid>
  );
};