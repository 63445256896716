import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridSelectionModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import debounce from "lodash.debounce";

import { FeatureGate } from "components/FeatureGate/FeatureGate";
import { FeatureFlagEnum } from "constants/constants";
import { GetUsersResponse } from "types/Api";
import { SecurityGroup } from "types/Group";
import { User } from "types/User";
import { useAppDialog } from "../../../hooks/useAppDialog";
import { useAppSnackbar } from "../../../hooks/useAppSnackbar";
import { useDeleteUsers } from "../../../hooks/useDeleteUsers";
import useForcePasswordChange from "../../../hooks/useForcePasswordChange";
import { AddNewUser } from "./AddNewUser";
import { AddUserToOrganization } from "./AddUserToOrganization";
import { DeleteUser } from "./DeleteUser";
import { DeleteUserFromOrganization } from "./DeleteUserFromOrganization";
import { DeleteUserFromOrganizationGroup } from "./DeleteUserFromOrganizationGroup";
import { EditUser } from "./EditUser/EditUser";
import { ImportUsers } from "./ImportUsers";

export interface UsersDataGridProps {
  isLoading: boolean;
  showAddUserModal: boolean;
  pageSize: number;
  setShowAddUserModal: (showModal: boolean) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onPageChange: (newPageChange: number) => void;
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  addUser?: "new" | "organization";
  deleteUser?: "app" | "organization" | "group";
  data?: GetUsersResponse;
  showTitle?: boolean;
  group?: SecurityGroup;
  hideEdit?: boolean;
  setUser?: (user: any) => void;
  disableSelectionOnClick?: boolean;
  singleSelection?: boolean;
}

export const UsersDataGrid = ({
  isLoading,
  data,
  pageSize,
  onPageSizeChange,
  onPageChange,
  onSearchChange,
  showTitle,
  addUser,
  group,
  deleteUser,
  hideEdit = false,
  setUser,
  disableSelectionOnClick = true,
  singleSelection
}: UsersDataGridProps): JSX.Element => {
  const { t } = useTranslation();
  const columns: GridColumns = useMemo(() => {
    const columns: GridColumns = [
      {
        field: "name",
        headerName: t("Name"),
        flex: 1,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row.firstName} ${params.row.lastName}`,
      },
      {
        field: "jobTitle",
        headerName: t("Title"),
        flex: 1,
        sortable: false,
      },
      {
        field: "mobilePhone",
        headerName: t("MobilePhone"),
        width: 120,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => params.value.number,
      },
      { field: "email", headerName: t("Email"), flex: 1, sortable: false },
    ];
    if (!hideEdit) {
      columns.push({
        field: "edit",
        headerName: "",
        sortable: false,
        align: "right",
        width: 100,
        renderCell: (params: GridRenderCellParams<unknown, User>) => (
          <Box sx={{ ml: "auto" }}>
            <EditUser userId={params.row.id} />
            {deleteUser === "app" && <DeleteUser userId={params.row.id} />}
            {deleteUser === "organization" && (
              <DeleteUserFromOrganization userId={params.row.id} />
            )}
            {deleteUser === "group" && (
              <DeleteUserFromOrganizationGroup
                userId={params.row.id as string}
                groupId={group?.id as string}
              />
            )}
          </Box>
        ),
      });
    }
    return columns;
  }, [t, deleteUser, group, hideEdit]);
  const rowCount = useMemo(() => data?.totalRecords ?? 0, [data?.totalRecords]);

  const [selectedUserIds, setSelectedUserIds] = useState<GridSelectionModel>(
    []
  );

  const onSelectionChange = useCallback((ids: GridSelectionModel) => {
    if(singleSelection && setUser) {
      setUser(ids);
    }
    setSelectedUserIds(ids);
  }, []);

  const { doForcePasswordChange, isPasswordChangeLoading } =
    useForcePasswordChange();

  const { onDeleteUsers } = useDeleteUsers({
    params: { hardDelete: true },
  });

  const { setSnackbarData } = useAppSnackbar();
  const { Dialog, dialogProps, openDialog, closeDialog } = useAppDialog();
  const {
    Dialog: DeleteDialog,
    dialogProps: dialogDeleteProps,
    openDialog: openDeleteDialog,
    closeDialog: closeDeleteDialog,
  } = useAppDialog();

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {showTitle && (
        <Typography
          sx={{
            color: (theme) => theme.palette.text.primary,
            textTransform: "uppercase",
            fontSize: "0.75rem",
            paddingY: 1,
          }}
        >
          {t("Users")}
        </Typography>
      )}
      <Stack sx={{ flex: 1 }}>
        <Stack direction="row" pb={2}>
          <TextField
            fullWidth
            placeholder={t("StartTypingToSearch")}
            sx={{ paddingTop: 0, maxWidth: 300 }}
            label={t("SearchUser")}
            hiddenLabel
            type="search"
            onChange={debounce(onSearchChange, 300)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Stack direction="row" pt={1}>
            <Box ml={2}>
              {addUser === "organization" && (
                <AddUserToOrganization group={group} />
              )}
              {addUser === "new" && <AddNewUser buttonVariant="contained" />}
            </Box>
            {addUser && (
              <>
                <FeatureGate name={FeatureFlagEnum.SHOW_USER_IMPORT}>
                  <Box ml={2}>
                    <ImportUsers />
                  </Box>
                </FeatureGate>
                <FeatureGate name={FeatureFlagEnum.SHOW_USER_EXPORT}>
                  <Box ml={2}>
                    <Button
                      variant="contained"
                      startIcon={<FileUploadIcon />}
                      onClick={() => {
                        //Do something
                      }}
                    >
                      {t("Export")}
                    </Button>
                  </Box>
                </FeatureGate>
              </>
            )}
            {selectedUserIds.length > 0 && (
              <>
                <Box ml={2}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      openDialog();
                    }}
                  >
                    Force change password
                  </Button>
                  <Dialog
                    title={t("ForcePasswordChange")}
                    {...dialogProps}
                    actionText={t("Confirm")}
                    buttonColor="primary"
                    onSubmit={() => {
                      doForcePasswordChange({
                        userIds: selectedUserIds as string[],
                      }).then(() => {
                        closeDialog();

                        setSnackbarData({
                          severity: "success",
                          message: t("PasswordForcingSuccessful"),
                        });
                      });
                    }}
                    loading={isPasswordChangeLoading}
                    maxWidth="xs"
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                      }}
                    >
                      {t("SureToForceChangePassMultiple")}
                    </Typography>
                  </Dialog>
                </Box>
                <Box ml={2}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      openDeleteDialog();
                    }}
                  >
                    {t("Delete")}
                  </Button>
                  <DeleteDialog
                    title={t("Delete")}
                    {...dialogDeleteProps}
                    actionText={t("Confirm")}
                    buttonColor="primary"
                    onSubmit={() => {
                      onDeleteUsers({
                        userIds: selectedUserIds as string[],
                      }).then(() => {
                        closeDeleteDialog();

                        setSnackbarData({
                          severity: "success",
                          message: t("UsersDeletedSuccessful"),
                        });
                      });
                    }}
                    loading={isPasswordChangeLoading}
                    maxWidth="xs"
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                      }}
                    >
                      {t("SureToMultipleDeleteUser")}
                    </Typography>
                  </DeleteDialog>
                </Box>
              </>
            )}
          </Stack>
        </Stack>
        <Paper sx={{ display: "flex", flex: 1 }} elevation={2}>
          <DataGrid
            checkboxSelection={!singleSelection}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            pageSize={pageSize}
            paginationMode="server"
            loading={isLoading}
            rowCount={rowCount}
            rows={data?.matches ?? []}
            columns={columns}
            onSelectionModelChange={onSelectionChange}
            disableSelectionOnClick={disableSelectionOnClick}
            sx={{
              ".MuiDataGrid-cell:focus": {
                outline: "unset",
              },
            }}
          />
        </Paper>
      </Stack>
    </Box>
  );
};